.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
  margin-top: 100px;
}
.wsnw {
  white-space: nowrap !important;
}
